const darkModeBtn = document.querySelector('#dark-mode')
const fontIncBtn = document.querySelector('#font-inc')
const fontDecBtn = document.querySelector('#font-dec')
const grayScaleBtn = document.querySelector('#grayscale')
const contrastBtn = document.querySelector('#high-contrast')
const html = document.querySelector('html')
const a11yContent = document.querySelector('.dropdown-container')
const aboutBackgroundImage = document.querySelector('.about-background')
const contactBackgroundImage = document.querySelector('.contact-container')
const menuBars = document.querySelector('.menu-bars')
const aboutText = document.querySelector('.about-text')
const skillHeader = document.querySelectorAll('h3')
const skillText = document.querySelectorAll('.skill-text')
const skillSection = document.querySelectorAll('.skill-section')
const panel = document.querySelectorAll('.tab-content')
const paradigmText = document.querySelectorAll('.tab-text')
const projectsHeaderContainer = document.querySelector(
    '.projects-header-container'
)
const projectsHeader = document.querySelector('.projects-header')
const projectDescription = document.querySelectorAll('.project-description')
const projectTitle = document.querySelectorAll('.project-title')
const projectBtn = document.querySelectorAll('.project-btn')
const formInput = document.querySelectorAll('.form-input')
const formMessage = document.querySelector('.text-area')
const submitBtn = document.querySelector('.submit-btn')
const resumeBtn = document.querySelector('.resume-btn')

document.addEventListener('click', (e) => {
    const isClickOutside = !a11yContent.contains(e.target)
    if (isClickOutside) {
        a11yContent.classList.remove('show-menu')
    } else {
        a11yContent.classList.add('show-menu')
    }
})

a11yContent.addEventListener('mouseover', () =>
    a11yContent.classList.add('show-menu')
)

a11yContent.addEventListener('mouseout', () =>
    a11yContent.classList.remove('show-menu')
)

let fontSize = 1

const handleDisableBtn = () => {
    if (fontSize === 0.8) {
        fontDecBtn.classList.add('disabled')
    } else if (fontSize === 1.3) {
        fontIncBtn.classList.add('disabled')
    } else {
        fontIncBtn.classList.remove('disabled')
        fontDecBtn.classList.remove('disabled')
    }
}

const handleFontSize = (direction) => {
    if (direction === 'increase' && fontSize < 1.3) {
        fontSize += 0.1
        fontSize = +fontSize.toFixed(1)
        html.style['font-size'] = `${fontSize}rem`
    } else if (direction === 'decrease' && fontSize > 0.8) {
        fontSize -= 0.1
        fontSize = +fontSize.toFixed(1)
        html.style['font-size'] = `${fontSize}rem`
    }
    handleDisableBtn()
}

const toggleDarkMode = () => {
    // prettier-ignore
    [aboutText, menuBars, formMessage, submitBtn, resumeBtn].forEach((item) =>
        item.classList.toggle('light-text')
    )
    html.classList.toggle('light-global')
    aboutBackgroundImage.classList.toggle('light-about-background')
    formMessage.classList.toggle('light-global')
    formMessage.classList.toggle('form-border')
    contactBackgroundImage.classList.toggle('light-contact-background')
    projectsHeaderContainer.classList.toggle('projects-background-light')
    projectsHeader.classList.toggle('projects-header-light')
    skillHeader.forEach((item) => item.classList.toggle('light-text'))
    skillText.forEach((item) => item.classList.toggle('light-text'))
    skillSection.forEach((item) => item.classList.toggle('light-border'))
    panel.forEach((item) => item.classList.toggle('light-global'))
    paradigmText.forEach((item) => item.classList.toggle('light-text'))
    projectTitle.forEach((item) => item.classList.toggle('light-text'))
    projectDescription.forEach((item) => item.classList.toggle('light-text'))
    projectBtn.forEach((item) => item.classList.toggle('light-text'))
    formInput.forEach((item) => {
        item.classList.toggle('light-global')
        item.classList.toggle('light-text')
        item.classList.toggle('form-border')
    })
    darkModeBtn.classList.toggle('active')
}

const toggleGreyScale = () => {
    html.classList.toggle('grayscale')
    grayScaleBtn.classList.toggle('active')
}

const toggleContrast = () => {
    html.classList.toggle('high-contrast')
    contrastBtn.classList.toggle('active')
}

fontIncBtn.addEventListener('click', () => handleFontSize('increase'))
fontDecBtn.addEventListener('click', () => handleFontSize('decrease'))
grayScaleBtn.addEventListener('click', toggleGreyScale)
contrastBtn.addEventListener('click', toggleContrast)
darkModeBtn.addEventListener('click', toggleDarkMode)
