import AOS from 'aos'
import 'aos/dist/aos.css'

import './styles/_index.scss'
import './images/cookbooks-desktop.png'
import './images/cookbooks-mobile.png'
import './images/metal-white.png'
import './images/substack.png'
import './images/time-blocker.png'
import './images/hearby.png'
import './images/favicon.png'
import './conner-linzy-resume.pdf'

const menuBars = document.querySelector('.menu-bars')
const navbar = document.querySelector('#navbar')
const navLink = document.querySelectorAll('.nav-link')
const border = document.querySelector('.border')

let recentClick = false

AOS.init({
    delay: 200, // values from 0 to 3000, with step 50ms
    duration: 800, // values from 0 to 3000, with step 50ms
    easing: 'ease-out-quart', // default easing for AOS animations
    mirror: true, // whether elements should animate out while scrolling past them
    offset: 50,
})

const handleMenuBarClick = () => {
    /** Transition menu bars to close icon */
    menuBars.classList.toggle('change')
    /** Show swipe up navbar */
    navbar.classList.toggle('visibility')
    /** Reset border animation */
    border.style.animation = 'none'
    border.offsetHeight /* trigger reflow */
    border.style.animation = null
}

const handleNavLinkClick = () => {
    recentClick = true
    if (window.innerWidth <= 800) {
        /** Hide mobile swipe-up nav on click of nav-link */
        navbar.classList.toggle('visibility')
        menuBars.classList.toggle('change')
    }
    /** For scroll event listener */
    setTimeout(() => {
        recentClick = false
    }, 2000)
}

/**
 *  Only hide navbar if:
 *  NOT switching quickly between links
 *  NOT at the top of the page
 */
let prev = 0
const handleScroll = () => {
    const scrollTop = window.scrollY
    if (!recentClick) {
        navbar.classList.toggle('hide-scroll-down', scrollTop > prev)
    }
    if (scrollTop < 100) {
        navbar.classList.remove('hide-scroll-down')
    }
    prev = scrollTop
}

menuBars.addEventListener('click', handleMenuBarClick)
navLink.forEach((link) => link.addEventListener('click', handleNavLinkClick))
window.addEventListener('scroll', handleScroll)
